/**
 *
 * @param {unknown} timers
 * @returns {{isValid: false, error: string} | {isValid: true}}
 */
export function validateHeartbeatTimersParam(timers) {
  if (!(timers instanceof Array)) {
    return {
      isValid: false,
      error: `[enableHeartBeatTimer] - 'timers' parameter if specified, has to be array of numbers. Received: '${timers}'`,
    };
  }

  if (timers.length === 0) {
    return {
      isValid: false,
      error: `[enableHeartBeatTimer] - 'timers' cannot be empty array`,
    };
  }

  for (let i = 0; i < timers.length; i++) {
    if (typeof timers[i] !== 'number') {
      return {
        isValid: false,
        error: `[enableHeartBeatTimer] - 'timers' array has to contain only numeric values. Received: '${timers}'`,
      };
    }
  }

  return { isValid: true };
}

import { query } from '../../utils/query';
import { stringEndsWith, stringStartsWith } from '../../utils/string';

const mobileURIRegex = new RegExp('^[a-z]+://.+$', 'i');

/**
 * @param {string[]} domains
 */
export function getMobileAppURIsFromDomains(domains) {
  const mobileAppURIs = [];

  for (let i = 0; i < domains.length; i++) {
    const uri = domains[i];

    if (mobileURIRegex.test(uri)) {
      mobileAppURIs.push(uri);
    }
  }

  return mobileAppURIs;
}

/**
 * @param {HTMLAnchorElement} anchor
 * @param {string[]} domains
 */
export function isLinkToMobileAppInDomains(anchor, domains) {
  const link = query.getAttributeValueFromNode(anchor, 'href');
  if (!link) return false;

  const hostAndPath = link.split('://');
  if (hostAndPath.length !== 2) return false;

  const host = hostAndPath[0].toLowerCase();
  const path = removeParameters(hostAndPath[1]);

  const mobileAppURIs = getMobileAppURIsFromDomains(domains);

  for (let i = 0; i < mobileAppURIs.length; i++) {
    const uri = mobileAppURIs[i];

    const hostAndPathAlias = uri.split('://');
    if (hostAndPathAlias.length !== 2) return false;

    const hostAlias = hostAndPathAlias[0].toLowerCase();
    const pathAlias = hostAndPathAlias[1];

    if (host === hostAlias && isSitePath(path, pathAlias)) {
      return true;
    }
  }

  return false;
}
/**
 * @param {string} path
 * @param {string} pathAlias
 */
export function isSitePath(path, pathAlias) {
  if (!stringStartsWith(pathAlias, '/')) {
    pathAlias = '/' + pathAlias;
  }

  if (!stringStartsWith(path, '/')) {
    path = '/' + path;
  }

  let matchesAnyPath = pathAlias === '/' || pathAlias === '/*';

  if (matchesAnyPath) {
    return true;
  }

  if (path === pathAlias) {
    return true;
  }

  pathAlias = String(pathAlias).toLowerCase();
  path = String(path).toLowerCase();

  // wildcard path support
  if (stringEndsWith(pathAlias, '*')) {
    // remove the final '*' before comparing
    pathAlias = pathAlias.slice(0, -1);

    // Note: this is almost duplicated from just few lines above
    matchesAnyPath = !pathAlias || pathAlias === '/';

    if (matchesAnyPath) {
      return true;
    }

    if (path === pathAlias) {
      return true;
    }

    // wildcard match
    return path.indexOf(pathAlias) === 0;
  }

  // we need to append slashes so /foobarbaz won't match a site /foobar
  if (!stringEndsWith(path, '/')) {
    path += '/';
  }

  if (!stringEndsWith(pathAlias, '/')) {
    pathAlias += '/';
  }

  return path.indexOf(pathAlias) === 0;
}

/**
 * @param {string} path
 */
function removeParameters(path) {
  const paramIndex = path.indexOf('?');
  if (paramIndex === -1) {
    return path;
  }

  return path.slice(0, paramIndex);
}

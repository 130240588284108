/**
 * Extract hostname from URL
 *
 * @param {string} url
 */
export function getHostName(url) {
  // scheme : // [username [: password] @] hostame [: port] [/ [path] [? query] [# fragment]]
  const e = new RegExp('^(?:(?:https?|ftp):)/*(?:[^@?]+@)?([^:/#]+)');
  const matches = e.exec(url);

  return matches ? matches[1] : url;
}

export function parseHref(hrefToParse) {
  const hrefParser = document.createElement('a');
  hrefParser.href = hrefToParse;
  return {
    href: hrefParser.href,
    hostname: hrefParser.hostname,
    pathname: hrefParser.pathname,
  };
}

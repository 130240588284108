export function isDefined(value) {
  return typeof value !== 'undefined';
}

export function isFunction(property) {
  return typeof property === 'function';
}

/**
 * @return bool Returns true if property is null, an Object, or subclass of Object (i.e., an instanceof String, Date, etc.)
 */
export function isObject(property) {
  return typeof property === 'object';
}

export function isString(property) {
  return typeof property === 'string' || property instanceof String;
}

/*
 * Is property a number or string representing a number?
 */
export function isNumberRepresentation(property) {
  return (
    typeof property === 'number' || (isString(property) && !isNaN(property) && property !== '')
  );
}

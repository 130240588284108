/**
 * @param {string} str
 * @param {string} prefix
 */
export function stringStartsWith(str, prefix) {
  str = String(str);
  return str.lastIndexOf(prefix, 0) === 0;
}

/**
 * @param {string} str
 * @param {string} suffix
 */
export function stringEndsWith(str, suffix) {
  str = String(str);
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

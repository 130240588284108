import { isDefined } from './types';

/**
 * Polyfill for IndexOf for IE6-IE8
 *
 * @template T
 * @param {T[]} theArray
 * @param {T} searchElement
 * @returns {number}
 * */
export function indexOfArray(theArray, searchElement) {
  if (theArray && theArray.indexOf) {
    return theArray.indexOf(searchElement);
  }

  // 1. Let O be the result of calling ToObject passing
  //    the this value as the argument.
  if (!isDefined(theArray) || theArray === null) {
    return -1;
  }

  if (theArray.length === 0) {
    return -1;
  }

  let index = 0;

  // 9. Repeat, while k < len
  while (index < theArray.length) {
    // a. Let Pk be ToString(k).
    //   This is implicit for LHS operands of the in operator
    // b. Let kPresent be the result of calling the
    //    HasProperty internal method of O with argument Pk.
    //   This step can be combined with c
    // c. If kPresent is true, then
    //    i.  Let elementK be the result of calling the Get
    //        internal method of O with the argument ToString(k).
    //   ii.  Let same be the result of applying the
    //        Strict Equality Comparison Algorithm to
    //        searchElement and elementK.
    //  iii.  If same is true, return k.
    if (theArray[index] === searchElement) {
      return index;
    }
    index++;
  }

  return -1;
}

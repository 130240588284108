function piwik_log(documentTitle, siteId, piwikUrl, customData) {
    'use strict';

    function getOption(optionName) {
        try {
            if (window['piwik_' + optionName]) {
                return window['piwik_' + optionName];
            }
        // eslint-disable-next-line no-empty
        } catch (ignore) { }

        return; // undefined
    }

    // instantiate the tracker
    var option,
        piwikTracker = window[process.env.OBJECT_NAME].getTracker(piwikUrl, siteId);

    // initialize tracker
    piwikTracker.setDocumentTitle(documentTitle);
    piwikTracker.setCustomData(customData);

    // handle Piwik globals
    option = getOption('tracker_pause');

    if (option) {
        piwikTracker.setLinkTrackingTimer(option);
    }

    option = getOption('download_extensions');

    if (option) {
        piwikTracker.setDownloadExtensions(option);
    }

    option = getOption('hosts_alias');

    if (option) {
        piwikTracker.setDomains(option);
    }

    option = getOption('ignore_classes');

    if (option) {
        piwikTracker.setIgnoreClasses(option);
    }

    piwikTracker.setTimingDataSamplingOnPageLoad(0)
    // track this page view
    piwikTracker.trackPageView();

    // default is to install the link tracker
    if (getOption('install_tracker')) {

        /**
         * Track click manually (function is defined below)
         *
         * @param string sourceUrl
         * @param int|string siteId
         * @param string piwikUrl
         * @param string linkType
         */
        window.piwik_track = function (sourceUrl, siteId, piwikUrl, linkType) {
            piwikTracker.setSiteId(siteId);
            piwikTracker.setTrackerUrl(piwikUrl);
            piwikTracker.trackLink(sourceUrl, linkType);
        };

        // set-up link tracking
        piwikTracker.enableLinkTracking();
    }
}

export default piwik_log;

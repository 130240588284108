/**
 * Helper function that returns page performance API (either older `PerformanceTiming` or newer `PerformanceNavigationTiming`)
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
 *
 */
export function getPerformanceAPI() {
  const performanceAPI =
    window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance;

  if (performanceAPI?.timing) {
    return performanceAPI.timing;
  }

  if (performanceAPI?.getEntriesByType) {
    const performanceNavigationTimingAPI = performanceAPI.getEntriesByType('navigation')[0];
    return performanceNavigationTimingAPI;
  }

  return undefined;
}
/**
 * @param {string} paramName
 * @param {number} value
 * @param {number} max
 */
export function getMetricParam(paramName, value, max) {
  if (value >= 0 && value <= max) {
    return '&' + paramName + '=' + value;
  }

  return '';
}

/**
 * @param {PerformanceTiming | PerformanceEntry} timing
 * @param {string} name
 */
export function getMetricValue(timing, name) {
  // New API  (PerformanceNavigationTiming) is already normalized, so
  // `navigationStart` property doesn't exist in it.
  return Math.round(timing[name] - (timing.navigationStart || 0));
}

/**
 * @param {PerformanceTiming | PerformanceEntry} timing
 * @param {{performanceGenerationTime: number}} overrides
 */
export function appendNavigationTimingPagePerformanceMetrics(timing) {
  // Value ranges for specific fields are defined in the API
  // https://developers.piwik.pro/en/latest/data_collection/api/http_api.html
  const MAX_INT16 = 65_535;
  const MAX_INT24 = 16_777_215;
  let metrics = '';

  // Unload event equal to 0 means there was no previous page to unload
  if (timing.unloadEventStart && timing.unloadEventStart !== 0) {
    metrics += getMetricParam('t_us', getMetricValue(timing, 'unloadEventStart'), MAX_INT16);
  }
  // Unload event equal to 0 means there was no previous page to unload
  if (timing.unloadEventEnd && timing.unloadEventEnd !== 0) {
    metrics += getMetricParam('t_ue', getMetricValue(timing, 'unloadEventEnd'), MAX_INT16);
  }
  // Redirect timing equal to 0 means there was no redirect
  if (timing.redirectStart && timing.redirectStart !== 0) {
    metrics += getMetricParam('t_rs', getMetricValue(timing, 'redirectStart'), MAX_INT16);
  }
  // Redirect timing equal to 0 means there was no redirect
  if (timing.redirectEnd && timing.redirectEnd !== 0) {
    metrics += getMetricParam('t_re', getMetricValue(timing, 'redirectEnd'), MAX_INT16);
  }
  if (timing.secureConnectionStart && timing.secureConnectionStart !== 0) {
    metrics += getMetricParam('t_ss', getMetricValue(timing, 'secureConnectionStart'), MAX_INT16);
  }
  if (timing.fetchStart && timing.fetchStart !== 0) {
    metrics += getMetricParam('t_fs', getMetricValue(timing, 'fetchStart'), MAX_INT16);
  }
  if (timing.domainLookupStart && timing.domainLookupStart !== 0) {
    metrics += getMetricParam('t_ds', getMetricValue(timing, 'domainLookupStart'), MAX_INT16);
  }
  if (timing.connectStart && timing.connectStart !== 0) {
    metrics += getMetricParam('t_cs', getMetricValue(timing, 'connectStart'), MAX_INT16);
  }
  if (timing.connectEnd && timing.connectEnd !== 0) {
    metrics += getMetricParam('t_ce', getMetricValue(timing, 'connectEnd'), MAX_INT16);
  }
  if (timing.requestStart && timing.requestStart !== 0) {
    metrics += getMetricParam('t_qs', getMetricValue(timing, 'requestStart'), MAX_INT16);
  }
  if (timing.responseStart && timing.responseStart !== 0) {
    metrics += getMetricParam('t_as', getMetricValue(timing, 'responseStart'), MAX_INT16);
  }
  if (timing.responseEnd && timing.responseEnd !== 0) {
    metrics += getMetricParam('t_ae', getMetricValue(timing, 'responseEnd'), MAX_INT16);
  }
  // Doesn't exist in new API
  if (timing.domLoading && timing.domLoading !== 0) {
    metrics += getMetricParam('t_dl', getMetricValue(timing, 'domLoading'), MAX_INT16);
  }
  if (timing.domInteractive && timing.domInteractive !== 0) {
    metrics += getMetricParam('t_di', getMetricValue(timing, 'domInteractive'), MAX_INT24);
  }
  if (timing.domContentLoadedEventStart && timing.domContentLoadedEventStart !== 0) {
    metrics += getMetricParam(
      't_ls',
      getMetricValue(timing, 'domContentLoadedEventStart'),
      MAX_INT24
    );
  }
  if (timing.domContentLoadedEventEnd && timing.domContentLoadedEventEnd !== 0) {
    metrics += getMetricParam(
      't_le',
      getMetricValue(timing, 'domContentLoadedEventEnd'),
      MAX_INT24
    );
  }
  if (timing.domComplete && timing.domComplete !== 0) {
    metrics += getMetricParam('t_dc', getMetricValue(timing, 'domComplete'), MAX_INT24);
  }
  if (timing.loadEventEnd && timing.loadEventEnd !== 0) {
    metrics += getMetricParam('t_ee', getMetricValue(timing, 'loadEventEnd'), MAX_INT24);
  }

  return metrics;
}

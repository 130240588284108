import { logConsoleError } from './utils/logger';

// asynchronous tracker (or proxy)
if (typeof window[process.env.QUEUE_NAME] !== 'object') {
    window[process.env.QUEUE_NAME] = [];
}

import trackerObject from './piwik';
// Piwik singleton and namespace
if (typeof window[process.env.OBJECT_NAME] !== 'object') {
    window[process.env.OBJECT_NAME] = trackerObject;
}

/* pluginTrackerHook */

(function () {
    'use strict';

    function hasPaqConfiguration()
    {
        if ('object' !== typeof window[process.env.QUEUE_NAME]) {
            return false;
        }
        // needed to write it this way for jslint
        var lengthType = typeof window[process.env.QUEUE_NAME].length;
        if ('undefined' === lengthType) {
            return false;
        }

        return !!window[process.env.QUEUE_NAME].length;
    }

    if (window
        && 'object' === typeof window.piwikPluginAsyncInit
        && window.piwikPluginAsyncInit.length) {
        var i = 0;
        for (i; i < window.piwikPluginAsyncInit.length; i++) {
            if (typeof window.piwikPluginAsyncInit[i] === 'function') {
                window.piwikPluginAsyncInit[i]();
            }
        }
    }

    if (window && window.piwikAsyncInit) {
        window.piwikAsyncInit();
    }

    if (!window[process.env.OBJECT_NAME].getAsyncTrackers().length) {
        // we only create an initial tracker when no other async tracker has been created yet in piwikAsyncInit()
        if (hasPaqConfiguration()) {
            // we only create an initial tracker if there is a configuration for it via _paq. Otherwise
            // Piwik.getAsyncTrackers() would return unconfigured trackers
            window[process.env.OBJECT_NAME].addTracker();
        } else {
            window[process.env.QUEUE_NAME] = {push: function (args) {
                logConsoleError(process.env.QUEUE_NAME + '.push() was used but Piwik tracker was not initialized before the ppms.js file was loaded. Make sure to configure the tracker via _paq.push before loading ppms.js. Alternatively, you can create a tracker via Piwik.addTracker() manually and then use _paq.push but it may not fully work as tracker methods may not be executed in the correct order.', args);
            }};
        }
    } else {
        logConsoleError(
            'Matomo javascript tracker or Piwik PRO javascript tracker was already initiated on this page. ' +
            'Please check the tracker setup. Matomo conflict can be resolved using these instructions: ' +
            'https://help.piwik.pro/support/questions/can-i-use-piwik-pro-and-matomo-at-the-same-time/.'
        );
    }

    window[process.env.OBJECT_NAME].trigger('PiwikInitialized', []);
    window[process.env.OBJECT_NAME].initialized = true;
}());


(function () {
    var jsTrackerType = (typeof window.AnalyticsTracker);
    if (jsTrackerType === 'undefined') {
        window.AnalyticsTracker = window[process.env.OBJECT_NAME];
    }
}());

import piwikLog from './log';
if (typeof window.piwik_log !== 'function') {
    window.piwik_log = piwikLog;
}
